import React from "react";

export const List = ({ children }) => {
    return (
      <div>
        { children }
      </div>
    )
  }
  
export const Item = ({ children }) => {
    return (
        <div className="bg-white-200 shadow p-5 mt-8">
          { children }
        </div>
    )
}