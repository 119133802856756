import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PostLinks from "../components/PostList"
import { GatsbySeo, BlogJsonLd } from "gatsby-plugin-next-seo"

const IndexPage = ({ data }) => {
  const posts = data.allMdx.edges.map(edge => {
    return edge.node
  })

  const metaImage = `${data.site.siteMetadata.siteUrl}/static/${data.site.siteMetadata.siteImage}`
  return (
    <Layout>
      <BlogJsonLd
        url={data.site.siteMetadata.url}
        //headline={data.site.siteMetadata.title} TODO:
        images={metaImage}
        //posts={[{ headline: 'Post 1', image: 'https://example.com/photos/1x1/photo.jpg' }, { headline: 'Post 2' }]} TODO: add blog posts
        authorName={data.site.siteMetadata.author}
        description={data.site.siteMetadata.title}
      />
      <GatsbySeo
        title={`${data.site.siteMetadata.title}'s Blog`}
        description={data.site.siteMetadata.description}
        canonical={data.site.siteMetadata.siteUrl}
        openGraph={{
          type: "website",
          url: data.site.siteMetadata.siteUrl,
          title: data.site.siteMetadata.title,
          description: data.site.siteMetadata.description,
          images: [
            // TODO: add other image sizes
            {
              url: metaImage,
            },
          ],
        }}
        twitter={{
          handle: data.site.siteMetadata.twitter,
          site: data.site.siteMetadata.twitter,
          cardType: "summary_large_image",
        }}
      />
      <h1 className="">Blog Posts</h1>
      <PostLinks posts={posts} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query blogIndex {
    site {
      siteMetadata {
        title
        author
        description
        twitter
        siteUrl
        siteImage
      }
    }
    allMdx {
      edges {
        node {
          id
          excerpt
          timeToRead
          tableOfContents
          frontmatter {
            title
            excerpt
            image
            tags
          }
          fields {
            slug
            date
          }
        }
      }
    }
  }
`
