import React from "react"
import { Link } from "gatsby"
import * as List from "./elements/List"
import DisplayTags from "./elements/TagList"
import * as helpers from "./helpers"

const PostList = ({ posts }) => {
  posts = posts.map(helpers.serializePost)

  posts = posts.sort((a, b) => b.moment.valueOf() - a.moment.valueOf())

  return (
    <List.List>
      {posts.map(post => {
        if (!post.fields) {
          return null
        }
        return (
          <List.Item key={post.id}>
            <h4>
              <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
            </h4>
            <div className="text-base italic">{post.fields.date}</div>
            <div className="text-base">{post.frontmatter.excerpt}...</div>
            <div className="mt-3">
              <DisplayTags tags={post.frontmatter.tags} />
            </div>
          </List.Item>
        )
      })}
    </List.List>
  )
}
export default PostList

